<template>
  <v-dialog
    v-model="dialog"
    width="1050"
    scrollable
    @keydown.esc="dialog = false"
  >
    <v-card class="elevation-3">
      <v-card-title class="primary">
        <h3 class="hpHeading white--text">Calculate Taxable Amount</h3>
      </v-card-title>
      <v-card-text class="pa-4">
        <v-row>
          <v-col sm="12" md="4">
            <BaseDatePickerWithText
              label="Invoice Date mm/dd/yyyy"
              v-model="invoiceDate"
              :rules="[(v) => !!v || 'required']"
            ></BaseDatePickerWithText>
          </v-col> </v-row
        ><v-row>
          <v-col sm="12" md="4">
            <LuExciseIssues
              label="Issue"
              v-model="issueID"
              :rules="[(v) => !!v || 'required']"
            ></LuExciseIssues>
          </v-col>
          <v-col sm="12" md="4">
            <LuExciseTransactionType
              :items="availableTransactionTypes"
              label="Transaction Type"
              v-model="transactionTypeID"
              :rules="[(v) => !!v || 'required']"
              clearable
            ></LuExciseTransactionType>
          </v-col>
          <v-col sm="12" md="4">
            <LuExciseTaxTypes
              :items="availableTaxTypes"
              label="Tax Type"
              v-model="taxTypeID"
              :rules="[(v) => !!v || 'required']"
              clearable
            ></LuExciseTaxTypes>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" md="4">
            <LuCounty
              autocomplete="new-password"
              label="Delivered To County"
              sortProperty="ID"
              v-model="deliveredToCountyID"
              :items="filteredCounties"
              :rules="[(v) => !!v || 'required']"
            ></LuCounty>
          </v-col>
          <v-col sm="12" md="4">
            <LuCity
              autocomplete="new-password"
              label="Delivered To City"
              v-model="deliveredToCityID"
              :items="filteredCities"
              clearable
            ></LuCity>
          </v-col>
          <v-col v-if="filteredSpecialDistrict.length > 0" sm="12" md="4">
            <LuSpecialDistrict
              label="Special District"
              v-model="specialDistrictID"
              :items="filteredSpecialDistrict"
              clearable
            ></LuSpecialDistrict>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" md="4">
            <v-text-field
              label="Tax Rate"
              :value="formatPercent(taxRate)"
              readonly
            ></v-text-field>
          </v-col>
          <v-col sm="12" md="4">
            <v-text-field
              prefix="$"
              label="Tax Due"
              v-model="taxDue"
            ></v-text-field>
          </v-col>
          <v-col sm="12" md="4">
            <v-text-field
              label="Taxable Amount"
              prefix="$"
              v-model="taxableAmount"
              readonly
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import {ref, reactive, watch, computed, inject} from 'vue'
import dayjs from 'dayjs'
import useExciseTaxRates from '@composables/useExciseTaxRates'
import LuExciseTaxTypes from '@components/select/a-single/LuExciseTaxTypes'
import LuExciseTransactionType from '@components/select/a-single/LuExciseTransactionType'
import LuCity from '@components/select/a-single/LuCity'
import LuCounty from '@components/select/a-single/LuCounty'
import LuExciseIssues from '@components/select/a-single/LuExciseIssues'
import LuSpecialDistrict from '@components/select/a-single/LuSpecialDistrict'
export default {
  //model: {
  //	prop: ''
  //},
  props: {},
  components: {
    LuCity,
    LuCounty,
    LuExciseIssues,
    LuSpecialDistrict,
    LuExciseTaxTypes,
    LuExciseTransactionType,
  },
  created() {
    this.loadLuCounty()
    this.loadLuCity()
    this.loadLuSpecialDistrict()
    this.loadLuExciseTaxTypes()
    this.loadLuExciseTransactionType()
  },
  setup(props, context) {
    const store = inject('vuex-store')
    const {getTaxRate} = useExciseTaxRates(store)
    return {
      getTaxRate,
    }
  },
  data() {
    return {
      dialog: false,
      invoiceDate: dayjs().toDate(),
      transactionTypeID: 1,
      issueID: 0,
      //TaxTypeID is required
      taxTypeID: 1,
      //SoldFromCountyID is required
      // SoldFromCountyID: 0,
      deliveredToCityID: 0,
      //DeliveredToCountyID is required
      deliveredToCountyID: 0,
      specialDistrictID: 0,
      taxableAmount: 0,
      //TaxPaid is required
      taxPaid: 0,
      taxDue: 0,
    }
  },
  computed: {
    ...get('luCounty', ['luCounty']),
    ...get('luCity', ['luCity']),
    ...get('luSpecialDistrict', ['luSpecialDistrict']),
    ...get('luExciseTaxTypes', ['luExciseTaxTypes']),
    ...get('luExciseTransactionType', ['luExciseTransactionType']),
    taxRate() {
      return parseFloat(
        this.getTaxRate(
          this.issueID,
          this.invoiceDate,
          this.deliveredToCountyID,
          // this.SoldFromCountyID,
          this.taxTypeID,
          this.deliveredToCityID,
          this.specialDistrictID
        ).toFixed(4)
      )
    },
    filteredCities() {
      if (this.deliveredToCountyID > 0) {
        let match = this.luCounty.find((x) => x.ID == this.deliveredToCountyID)
        if (match) {
          return match.Cities.filter(
            (x) => x.IsActive && x.DORJurisdiction != null
          )
        }
      } else {
        return this.luCity.filter(
          (x) => x.IsActive && x.DORJurisdiction != null
        )
      }
    },
    availableTaxTypes() {
      var list = this.luExciseTaxTypes.filter((x) => true)
      if (this.transactionTypeID > 0) {
        let match = this.luExciseTransactionType.find(
          (x) => x.ID == this.transactionTypeID
        )
        if (match) {
          list = match.TaxTypes
        }
      }
      return list.filter((item) => {
        return item.IsActive
      })
    },
    availableTransactionTypes() {
      let list = this.luExciseTransactionType.filter((x) => true)
      if (this.taxTypeID > 0) {
        let match = this.luExciseTaxTypes.find((x) => x.ID == this.taxTypeID)
        if (match) {
          list = match.TransactionTypes
        }
      }
      return list.filter((i) => i.IsActive)
    },
    availableCounties() {
      var list = []
      var fullList = this.luCounty
      list = this.luCounty.filter((i) => i.DORJurisdiction != null)

      // if existing record make sure the previous selection is available even if no longer active
      if (this.value > 0) {
        var selectedItem = fullList.find((i) => i.ID == this.value)
        if (!list.find((i) => i.ID == this.value) && selectedItem) {
          list.push(selectedItem)
        }
      }
      // sort by column
      list.sort((a, b) => {
        //set the property to sort on
        let textField = 'Name'
        let sort = a[textField] > b[textField] ? 1 : -1
        return sort
      })
      return list
    },
    filteredCounties() {
      if (this.deliveredToCityID > 0) {
        let match = this.luCity.find((x) => x.ID == this.deliveredToCityID)
        if (match) {
          return match.Counties.filter((x) => x.DORJurisdiction != null)
        }
      } else {
        return this.availableCounties
      }
    },
    filteredSpecialDistrict() {
      var list = []
      var fullList = this.luSpecialDistrict
      if (this.deliveredToCountyID > 0) {
        let match = this.luCounty.find((x) => x.ID == this.deliveredToCountyID)
        if (match) {
          list = match.SpecialDistricts.filter(
            (x) => x.IsActive && x.DORJurisdiction != null
          )
        }
      }
      // if existing record make sure the previous selection is available even if no longer active
      // if (this.value > 0) {
      //   var selectedItem = fullList.find((i) => i.ID == this.value)
      //   if (!list.find((i) => i.ID == this.value) && selectedItem) {
      //     list.push(selectedItem)
      //   }
      // }
      // sort by column
      list.sort((a, b) => {
        //set the property to sort on
        let textField = 'SpecialDistrict'
        let sort = a[textField] > b[textField] ? 1 : -1
        return sort
      })
      return list
    },
  },
  methods: {
    loadLuCounty: call('luCounty/loadLuCounty'),
    loadLuCity: call('luCity/loadLuCity'),
    loadLuSpecialDistrict: call('luSpecialDistrict/loadLuSpecialDistrict'),
    loadLuExciseTaxTypes: call('luExciseTaxTypes/loadLuExciseTaxTypes'),
    loadLuExciseTransactionType: call(
      'luExciseTransactionType/loadLuExciseTransactionType'
    ),
    openCalculator() {
      this.dialog = true
    },
    formatPercent(val) {
      if (val != null && val != undefined) {
        return parseFloat((val * 100).toFixed(2)) + '%'
      }
    },
  },
  watch: {
    filteredCities(nval, oval) {
      // console.devlog(nval, oval)
      let match = nval.find((x) => x.ID == this.deliveredToCityID)
      if (!match) {
        this.deliveredToCityID = 0
      }
    },
    filteredSpecialDistrict(nval, oval) {
      // console.devlog(nval, oval)
      let match = nval.find((x) => x.ID == this.specialDistrictID)
      if (!match) {
        this.specialDistrictID = 0
      }
    },
    taxDue(nval, oval) {
      this.taxableAmount = parseFloat((nval / this.taxRate).toFixed(2))
    },
    taxRate(nval, oval) {
      this.taxableAmount = parseFloat((this.taxDue / nval).toFixed(2))
    },
  },
}
</script>
<style scoped></style>
