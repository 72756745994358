<template>
  <v-container fluid>
    <BaseStickyHeader v-if="selectedProject">
      <v-row>
        <v-col sm="12" md="8">
          <v-row justify="start" no-gutters>
            <v-col sm="auto" class="ml-2">
              <div v-if="selectedProject.SubEntity">
                <span>{{ selectedProject.SubEntity.Entity.FullName }}</span>
                <span v-show="$vuetify.breakpoint.mdAndUp">
                  -
                  {{ selectedProject.SubEntity.Description }}
                </span>
              </div>
              <div v-show="$vuetify.breakpoint.mdAndUp">
                {{ selectedProject.AuditStart | dateLocal }} -
                {{ selectedProject.AuditEnd | dateLocal }}
              </div>
              <router-link
                :to="`/project/${selectedProject.SubEntityID}/${selectedProject.ID}`"
                class="font-weight-bold"
              >
                {{ selectedProject.ProjectNumber }}
                <span v-show="$vuetify.breakpoint.mdAndUp">
                  -
                  {{ selectedProject.Description }}
                </span>
              </router-link>
            </v-col>
            <!-- <v-col sm="auto" class="ml-2"></v-col> -->
          </v-row>
        </v-col>
        <v-col sm="12" md="4">
          <v-row class="pt-2 pr-2" justify="end" align="center">
            <BaseTooltipButton
              icon="mdi-arrow-left"
              :to="`/project/${selectedProject.SubEntityID}/${selectedProject.ID}`"
              >Back To Project</BaseTooltipButton
            >
            <!-- <BaseTooltipButton
              icon="mdi-calculator"
              @click="$refs.calculator.openCalculator()"
              >Calculate Taxable Amount</BaseTooltipButton
            > -->
            <BaseTooltipButton
              icon="mdi-text-box-plus-outline"
              @click="$refs.exciseExceptionsForm.addExciseExceptions()"
              >Insert Excise Exception</BaseTooltipButton
            >
          </v-row>
          <v-row class="pr-2" justify="end" align="center">
            <v-text-field
              label="add num"
              class="shrink"
              style="width: 60px"
              v-if="activeTab == 'add'"
              v-model="itemNum"
              type="number"
              @keydown.enter="addExceptions"
            ></v-text-field>
            <BaseTooltipButton
              v-if="activeTab == 'add'"
              icon="mdi-plus"
              @click="addExceptions"
              >Add rows to datasheet</BaseTooltipButton
            >

            <BaseTooltipButton
              v-if="activeTab == 'edit'"
              icon="mdi-plus"
              @click="addException"
              >Add row to datasheet</BaseTooltipButton
            >
          </v-row></v-col
        >
      </v-row></BaseStickyHeader
    >
    <ExciseExceptionsDialogForm
      ref="exciseExceptionsForm"
      @itemAdded="itemAdded"
      :vendorCustomer="vendorCustomer"
      :references="references"
      :stratum="stratum"
      :projectDetailsId="selectedProject.ProjectDetailsID"
    >
    </ExciseExceptionsDialogForm>
    <ExciseExceptionsCalculator ref="calculator"> </ExciseExceptionsCalculator>
    <v-dialog v-model="unsavedDialog" width="350" scrollable>
      <v-card class="elevation-3">
        <v-card-title class="primary">
          <h3 class="hpHeading white--text">Continue?</h3>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">
              Are you sure you want to leave this page - you have not saved your
              changes?
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="
              unsavedDialog = false
              routeTo(false)
              routeTo = null
            "
            >No</v-btn
          >
          <v-btn v-if="routeTo" @click="changeRoute" outlined color="primary"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tabs v-model="activeTab" @change="tabSwitch">
      <v-tab v-for="tab in tabs" :key="tab.id" :to="tab.to">
        {{ tab.name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab" @change="updateRouter($event)">
      <router-view ref="exceptionGrid" :key="activeTab" />
    </v-tabs-items>
  </v-container>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import {authMethods, authComputed} from '@state/helpers'
import ExciseExceptionsDialogForm from '@components/form/add-edit/ExciseExceptionsDialog'
import ExciseExceptionsCalculator from '@components/dialogs/exciseExceptionCalculator'
// import ExciseExceptionsEdit from '@components/grid/ExciseExceptionsEdit'
// import ExciseExceptionsAdd from '@components/grid/ExciseExceptionsAdd'
export default {
  props: {},
  components: {
    ExciseExceptionsDialogForm,
    ExciseExceptionsCalculator,
    // ExciseExceptionsEdit,
    // ExciseExceptionsAdd,
  },
  page() {
    return {
      title: this.selectedProject
        ? this.selectedProject.ProjectNumber + ' Exceptions'
        : 'Project Exceptions',
    }
  },
  data() {
    return {
      activeTab: 'edit',
      itemNum: 1,
      unsavedDialog: false,
      routeTo: null,
      calculatorDialog: false,
    }
  },
  created() {
    // this.loadLuCounty()
    // this.loadLuCity()
    // this.loadLuSpecialDistrict()
    // this.loadLuExciseIssues()
    // this.loadLuExciseTransactionType()
    // this.loadLuExciseTaxTypes()
    if (this.$route.params.projectId) {
      this.loadSelectedProject(this.$route.params.projectId).then(() => {
        this.loadSelectedProjectExciseExceptionsDuplicateCount()
        this.loadSelectedProjectExciseExceptionReferencesObject()
        this.loadSelectedProjectExciseExceptionVendorCustomerObject()
        this.loadSelectedProjectExciseStratum()
        this.loadSelectedProjectExciseExceptionUsers()
        this.getRelatedOptions()
      })
    }
    if (this.$route.name == 'exceptionDatasheet') {
      this.$router.replace(this.$route.path + `/${this.tabs[0].to}`)
    }
  },
  beforeRouteUpdate(to, from, next) {
    //this is called when navigating between the edit and add tabs
    // console.devlog(
    //   'beforeRouteUpdate - datasheet',
    //   to,
    //   from,
    //   this.datasheetUnsavedItems
    // )
    if (!this.datasheetUnsavedItems) {
      next()
    } else {
      this.routeTo = next
      this.confirmUnsavedChanges()
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.datasheetUnsavedItems) {
      next()
    } else {
      this.routeTo = next
      this.confirmUnsavedChanges()
    }
  },
  beforeDestroy() {
    console.red('datasheet before destroy')
  },
  computed: {
    selectedProject: get('project/selectedProject'),
    duplicateCount: get('project/selectedProjectExciseExceptionDuplicateCount'),
    vendorCustomer: get(
      'project/selectedProjectExciseExceptionVendorCustomerObject'
    ),
    references: get('project/selectedProjectExciseExceptionReferencesObject'),
    stratum: get('project/selectedProjectExciseStratum'),
    exceptionUsers: get('project/selectedProjectExciseExceptionUsers'),
    luExciseTaxTypes: get('luExciseTaxTypes/luExciseTaxTypes'),
    ...sync('exciseExceptions', ['datasheetUnsavedItems']),
    ...get('project', ['selectedProject']),
    tabs() {
      return [
        {id: 1, name: 'Search / Edit', to: 'edit'},
        {id: 2, name: 'Import / Add', to: 'add'},
        {
          id: 3,
          name:
            this.duplicateCount > 0
              ? `Possible Duplicates (${this.duplicateCount})`
              : 'Possible Duplicates',
          to: 'duplicates',
        },
      ]
    },
  },
  methods: {
    ...call('project', [
      'loadSelectedProject',
      'loadSelectedProjectExciseStratum',
      'loadSelectedProjectExciseExceptionsDuplicateCount',
      'loadSelectedProjectExciseExceptionReferencesObject',
      'loadSelectedProjectExciseExceptionVendorCustomerObject',
      'loadSelectedProjectExciseExceptionUsers',
    ]),
    ...call('exciseExceptions', ['getRelatedOptions']),
    loadLuCounty: call('luCounty/loadLuCounty'),
    loadLuCity: call('luCity/loadLuCity'),
    loadLuSpecialDistrict: call('luSpecialDistrict/loadLuSpecialDistrict'),
    loadLuExciseIssues: call('luExciseIssues/loadLuExciseIssues'),
    loadLuExciseTransactionType: call(
      'luExciseTransactionType/loadLuExciseTransactionType'
    ),
    loadLuExciseTaxTypes: call('luExciseTaxTypes/loadLuExciseTaxTypes'),
    getTabPath(path) {
      // Get the path without losing params. Usefull e.g. if you have a :locale in your url:
      return this.$router.resolve({path: path, params: this.$route.params}).href
    },
    updateRouter(path) {
      // Gets called upon swipe.
      console.blue('updateRoute', path)
      this.$router.push(path)
    },
    addException() {
      this.$refs.exceptionGrid.appendRow()
    },
    addExceptions() {
      this.$refs.exceptionGrid.appendRow(this.itemNum)
    },
    tabSwitch(val) {
      console.orange('tabSwitch', val)
    },
    itemAdded(item) {
      this.$refs.exceptionGrid.itemAdded({index: -1, item: item})
    },
    confirmUnsavedChanges() {
      this.unsavedDialog = true
    },
    changeRoute() {
      this.datasheetUnsavedItems = false
      this.routeTo()
    },
  },
  watch: {},
}
</script>
<style scoped>
</style>